import { Component } from 'preact';
import { route } from 'preact-router';
import axios from 'axios';

import { ReviewConfigContext } from './ReviewConfigContext';

export class LoadReviewJob extends Component {
  componentDidMount() {
    const { reviewId } = this.props;
    axios
      .get(`/review/config/${reviewId}`)
      .then(response => {
        if (!response.data.place_name) {
          return route('/');
        }

        const reviewSites = response.data.review_sites;
        this.context.setConfig({ ...response.data, reviewSites });
        setTimeout(() => {
          if (response.data.skip_ratings_screen) {
            route(`/${reviewId}/prompt/0`);
          } else {
            route(`/${reviewId}/rating`);
          }
        }, 10);
      })
      .catch(() => route('/'));
  }
  render() {
    return null;
  }
}

LoadReviewJob.contextType = ReviewConfigContext;
