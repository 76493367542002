import { ReviewConfigContext } from '../ReviewConfigContext';

export const Button = ({ onClick, children, ...otherProps }) => (
  <ReviewConfigContext.Consumer>
    {({ config }) => (
      <button
        class="button"
        {...otherProps}
        style={{
          fontSize: '20px',
          maxWidth: '275px',
          minWidth: '275px',
          textAlign: 'center',
          width: '100%',
          height: '50px',
          marginBottom: '20px',
          textDecoration: 'none',
          color: config.button_primary_text_color,
          backgroundColor: config.button_primary_background_color,
          border: `1px solid ${config.button_primary_border_color}`,
        }}
        onClick={onClick}
      >
        {children}
      </button>
    )}
  </ReviewConfigContext.Consumer>
);
