import axios from 'axios';
import { Button, Prompt, Screen } from './components';
import { ReviewConfigContext } from './ReviewConfigContext';

const startReview = (reviewFeedbackId, serviceName, serviceUrl) => () =>
  axios
    .post(`/review/status/${reviewFeedbackId}`, {
      status: 'started',
      service_name: serviceName,
    })
    .then(() => (location.href = serviceUrl));

export default props => (
  <ReviewConfigContext.Consumer>
    {({ config }) => (
      <Screen id="prompt-all">
        <Prompt>Leave your review on</Prompt>
        {config.reviewSites &&
          config.reviewSites.map(site => (
            <Button
              id={site.service_name}
              key={site.service_name}
              onClick={startReview(
                props.reviewFeedbackId,
                site.service_name,
                site.url,
              )}
            >
              {site.service_name.toUpperCase()}
            </Button>
          ))}
      </Screen>
    )}
  </ReviewConfigContext.Consumer>
);
