import { ReviewConfigContext } from '../ReviewConfigContext';
import numaLogo from '../../assets/wordContrast.svg';

const makeStyles = config => ({
  screen: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: config.body_background_color,
    color: config.body_text_color,
  },
  header: {
    backgroundColor: config.header_background_color,
    color: config.header_text_color,
    borderBottom: `1px solid ${config.header_border_color}`,
    minHeight: '95px',
    textAlign: 'center',
    marginBottom: '10px',
  },
  businessName: {
    fontSize: '26px',
    // match the header minHeight
    lineHeight: '95px',
  },
  logo: { maxHeight: '55px', paddingTop: '20px', paddingBottom: '20px' },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: config.body_text_color,
  },
  footer: {
    fontSize: '12px',
    color: '#050712',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: '20px',
    textDecoration: 'none',
  },
  numa: {
    maxHeight: '15px',
    color: '#050712',
    verticalAlign: 'text-bottom',
    marginLeft: '5px',
  },
  poweredby: {
    alignContent: 'center',
    lineHeight: '15px',
  },
});

export const Screen = props => (
  <ReviewConfigContext.Consumer>
    {({ config }) => {
      const styles = makeStyles(config);
      return (
        <div class="screen" id={props.id} style={styles.screen}>
          {!props.noHeader && (
            <div class="screen-header" style={styles.header}>
              {config.logo_url ? (
                <img style={styles.logo} src={config.logo_url} />
              ) : (
                <span style={styles.businessName}>{config.place_name}</span>
              )}
            </div>
          )}
          <div class="screen-content" style={styles.content}>
            {props.children}
          </div>
          <a
            class="screen-footer"
            style={styles.footer}
            href="https://numahelps.com"
          >
            <span style={styles.poweredby}>powered by</span>
            <img style={styles.numa} src={numaLogo} />
          </a>
        </div>
      );
    }}
  </ReviewConfigContext.Consumer>
);
