import { route } from 'preact-router';

import { ReviewConfigContext } from './ReviewConfigContext';

export const SmartRoute = props => (
  <ReviewConfigContext.Consumer>
    {({ config }) => {
      if (!props.reviewId) {
        route('/', true);
        return null;
      }
      if (!config || !config.place_name) {
        route(`/${props.reviewId}`, true);
        return null;
      }

      const { component: Component, ...otherProps } = props;

      return (
        <Component
          {...otherProps}
          reviewFeedbackId={config.review_feedback_id}
        />
      );
    }}
  </ReviewConfigContext.Consumer>
);
