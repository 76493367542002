import { createContext } from 'preact';
import { useState } from 'preact/hooks';

export const ReviewConfigContext = createContext();

export const ReviewConfig = ({ children }) => {
  const [config, setConfig] = useState({});
  return (
    <ReviewConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ReviewConfigContext.Provider>
  );
};
