import { ReviewConfigContext } from './ReviewConfigContext';

const makeMarkup = styles => ({ __html: styles });

export const StyleOverride = () => (
  <ReviewConfigContext.Consumer>
    {({ config }) => {
      if (config.override_stylesheet) {
        return (
          <style
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={makeMarkup(config.stylesheet_override)}
          />
        );
      }
      return null;
    }}
  </ReviewConfigContext.Consumer>
);
