import { Component } from 'preact';
import Router from 'preact-router';

import { SmartRoute } from './SmartRoute';
import { ReviewConfig } from './ReviewConfigContext';
import { LoadReviewJob } from './LoadReviewJob';
import ErrorPage from './ErrorPage';
import GetRating from './GetRating';
import ReviewPrompt from './ReviewPrompt';
import ReviewPromptChoices from './ReviewPromptChoices';
import SubmitFeedback from './SubmitFeedback';
import Thanks from './Thanks';

import { StyleOverride } from './StyleOverride';

// eslint-disable-next-line react/prefer-stateless-function
export default class App extends Component {
  render() {
    return (
      <ReviewConfig>
        <StyleOverride />
        <Router>
          <SmartRoute component={GetRating} path="/:reviewId/rating" />
          <SmartRoute
            component={ReviewPrompt}
            path="/:reviewId/prompt/:serviceId"
          />
          <SmartRoute
            component={ReviewPromptChoices}
            path="/:reviewId/promptAll"
          />
          <SmartRoute
            component={SubmitFeedback}
            path="/:reviewId/feedback/:rating"
          />
          <SmartRoute component={Thanks} path="/:reviewId/thanks" />
          <LoadReviewJob path="/:reviewId" />
          <ErrorPage default />
        </Router>
      </ReviewConfig>
    );
  }
}
