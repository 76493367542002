import { route } from 'preact-router';
import axios from 'axios';
import { Prompt, Button, Screen } from './components';
import { ReviewConfigContext } from './ReviewConfigContext';

const startReview = (reviewFeedbackId, serviceName, serviceUrl) => () =>
  axios
    .post(`/review/status/${reviewFeedbackId}`, {
      status: 'started',
      service_name: serviceName,
    })
    .then(() => (location.href = serviceUrl));

export default ({ serviceId, reviewId, reviewFeedbackId }) => (
  <ReviewConfigContext.Consumer>
    {({ config }) => {
      const reviewSite = config.reviewSites[serviceId];
      const nextServiceId = Number(serviceId) + 1;
      let nextRoute = null;
      if (config.reviewSites[nextServiceId]) {
        nextRoute = `/${reviewId}/prompt/${nextServiceId}`;
      } else if (config.reviewSites.length > 1) {
        nextRoute = `/${reviewId}/promptAll`;
      }

      return (
        <Screen id="prompt">
          <Prompt>
            Would you like to leave a review on{' '}
            <strong>{reviewSite.service_name}</strong>?
          </Prompt>
          <Button
            onClick={startReview(
              reviewFeedbackId,
              reviewSite.service_name,
              reviewSite.url,
            )}
          >
            YES
          </Button>
          {nextRoute && (
            <div id="next-link" onClick={() => route(nextRoute)}>
              No, use a different site
            </div>
          )}
        </Screen>
      );
    }}
  </ReviewConfigContext.Consumer>
);
