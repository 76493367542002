import { Component } from 'preact';
import { route } from 'preact-router';

import { Button, Prompt, Screen } from './components';
import axios from 'axios';

const startReview = (reviewFeedbackId, serviceName) =>
  axios.post(`/review/status/${reviewFeedbackId}`, {
    status: 'started',
    service_name: serviceName,
  });

export default class SubmitFeedback extends Component {
  state = { value: '' };

  onSubmit = () => {
    const { reviewId, reviewFeedbackId } = this.props;
    const { value } = this.state;
    axios
      .post(`/review/status/${reviewFeedbackId}`, {
        status: 'completed',
        content: value,
      })
      .then(() => route(`/${reviewId}/thanks`));
  };

  onInput = e => {
    const { value } = e.target;
    this.setState({ value });
  };

  componentDidMount() {
    const { reviewFeedbackId } = this.props;
    startReview(reviewFeedbackId, 'direct');
  }

  render() {
    const { rating } = this.props;
    const { value } = this.state;
    return (
      <Screen id="feedback">
        <Prompt>
          {(rating === '3' || rating === '4') && 'Great! '}How could your
          experience have been better?
        </Prompt>
        <textarea
          id="feedback-input"
          rows="4"
          cols="50"
          type="textarea"
          value={value}
          onInput={this.onInput}
          style={{
            fontSize: '16px',
            resize: 'none',
            padding: '10px',
            marginBottom: '25px',
            maxWidth: '290px',
          }}
        />
        <Button disabled={!value} onClick={this.onSubmit}>
          SUBMIT FEEDBACK
        </Button>
      </Screen>
    );
  }
}
